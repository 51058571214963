/* @jsx UiSchema.createElement */
import { UiSchema } from '@rushplay/forms'

export const step1 = (
  <UiSchema.Box display="grid" gridGap={0}>
    <UiSchema.Control
      forwardProps={{
        autoComplete: 'email',
        inputMode: 'email',
      }}
      scope="#/properties/email"
    />
    <UiSchema.Control
      forwardProps={{
        autoComplete: 'new-password',
      }}
      scope="#/properties/password"
    />
    <UiSchema.Box display="grid" gridTemplateColumns="80px 1fr" gridGap={1}>
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'tel-country-code',
        }}
        scope="#/properties/countryCallingCode"
      />
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'tel',
          inputMode: 'tel',
        }}
        scope="#/properties/phonenumber"
      />
    </UiSchema.Box>
  </UiSchema.Box>
)

export const step2 = (
  <UiSchema.Box display="grid" gridGap={0} color="g-text">
    <UiSchema.Box
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      gridGap={1}
    >
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'given-name',
        }}
        scope="#/properties/firstName"
      />
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'family-name',
        }}
        scope="#/properties/lastName"
      />
    </UiSchema.Box>
    <UiSchema.Control
      forwardProps={{
        autoComplete: 'street-address',
      }}
      scope="#/properties/street"
    />
    <UiSchema.Box
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      gridGap={1}
    >
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'postal-code',
        }}
        scope="#/properties/zip"
      />
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'address-level2',
        }}
        scope="#/properties/city"
      />
    </UiSchema.Box>
    <UiSchema.Control
      forwardProps={{
        autoComplete: 'country-name',
      }}
      scope="#/properties/country"
    />
    <UiSchema.Box
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gridGap={1}
      pb={0}
    >
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'bday-day',
        }}
        scope="#/properties/bdayDay"
      />
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'bday-month',
        }}
        scope="#/properties/bdayMonth"
      />
      <UiSchema.Control
        forwardProps={{
          autoComplete: 'bday-year',
          maxLength: '4',
        }}
        scope="#/properties/bdayYear"
      />
    </UiSchema.Box>
    <UiSchema.Control
      forwardProps={{
        autoComplete: 'one-time-code',
      }}
      scope="#/properties/phoneVerificationCode"
    />
    <UiSchema.Control scope="#/properties/promotional" />
  </UiSchema.Box>
)
