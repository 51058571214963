import * as R from 'ramda'
import { connect } from 'react-redux'

import * as player from '../player'

import { DepositLimitInformation as Component } from './deposit-limit-info'

export const DepositLimitInformation = connect(
  state => {
    const depositLimits = player.getDepositLimits(state.player)
    const lowestLimit = player.getDepositLimitRemainder(state.player)

    return {
      limit: R.find(R.propEq('leftToDepositCents', lowestLimit), depositLimits),
    }
  },
  dispatch => ({
    onFetch: () => dispatch(player.fetchDepositInformation()),
  })
)(Component)
