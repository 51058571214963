import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Notifications from '@rushplay/notifications'

import * as Configuration from './configuration'
import * as ServerConfig from './server-configuration'
import { HtmlContent } from './html-content'
import { InputField } from './input-field'
import { toPhoneNumberFormat } from './to-phone-number-format'

export function PhoneVerificationCodeInput(props) {
  const featureEnabled = ReactRedux.useSelector(state =>
    Configuration.getLoggedOutPhoneVerification(state.configuration)
  )
  const [enabled, setEnabled] = React.useState(featureEnabled)
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()
  const { country, countries, locale } = ServerConfig.useContext()
  const [visible, setVisible] = React.useState(false)

  const { value: phoneNumber } = Forms.useField('#/properties/phonenumber', {
    noRegister: true,
  })
  const { value: formCountry } = Forms.useField('#/properties/country', {
    noRegister: true,
  })
  const { value: countryCallingCode } = Forms.useField(
    '#/properties/countryCallingCode',
    {
      noRegister: true,
    }
  )

  const fullNumber = `+${countryCallingCode}${R.replace(
    /^0+/,
    '',
    phoneNumber
  )}`

  function handleResend() {
    dispatch(
      Api.requestPhoneVerificationCode(
        {
          countryCallingCode: `+${countryCallingCode}`,
          language: locale.language || 'en',
          phone: phoneNumber,
        },
        {
          success: () => {
            setVisible(true)
          },
          failure: () =>
            Notifications.add({
              message: 'errors.phone-verification-request.failed',
              level: 'error',
            }),
          version: 1,
        }
      )
    )
  }

  React.useEffect(() => {
    const enabledInCountry = R.path(
      ['loggedOutPhoneVerification'],
      R.find(R.pathEq(['name'], formCountry), countries)
    )
    setEnabled(enabledInCountry)
  }, [formCountry, countries])

  React.useEffect(() => {
    if (enabled) {
      handleResend()
    }
  }, [enabled])

  if (!enabled) {
    return null
  }

  return (
    <Common.Box display="grid" gridGap={0} fontSize={1} py={0}>
      <HtmlContent
        html={{
          __html: i18n.translate('register.phone-verification.info', {
            number: toPhoneNumberFormat(country.alpha2, fullNumber),
          }),
        }}
      />
      <Common.Box
        display="grid"
        gridTemplateColumns="70% 1fr"
        gridGap={1}
        alignItems="center"
      >
        <InputField {...props} />
        <Common.Flex flexDirection="column">
          {visible && (
            <Common.Box>
              {i18n.translate('register.phone-verification.code-sent')}
            </Common.Box>
          )}
          <Common.Box
            onClick={handleResend}
            textAlign="center"
            fontWeight="bold"
            pt={1}
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
          >
            {i18n.translate('register.phone-verification.resend')}
          </Common.Box>
        </Common.Flex>
      </Common.Box>
    </Common.Box>
  )
}
