import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import { lazy as loadable } from '@loadable/component'

import * as Cookies from './cookies-module'
import * as Session from './session'

const LoginPage = loadable(() => import('./login-page'))
const LandingPage = loadable(() => import('./landing-page'))

export function Home() {
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const [returningPlayer] = Cookies.useCookie('returning_player')

  if (!authenticated) {
    if (returningPlayer) {
      return <LoginPage />
    }

    return <LandingPage />
  }

  return (
    <ReactRouter.Redirect
      exact
      from="/"
      to={{
        pathname: '/casino',
        search: returningPlayer ? null : '?wallet=deposit',
      }}
    />
  )
}
