import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Session from '../session'
import * as icons from '../icons'
import { QuickNavigation } from '../quick-navigation'
import { useGameInfoUrlQuery } from '../use-game-info-url-query'

const Wrapper = styled.div`
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
  ${css({
    display: ['flex', 'none'],
  })}
`

export function QuickNavigationMenu(props) {
  const location = ReactRouter.useLocation()
  const gameInfoQuery = useGameInfoUrlQuery()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )

  // Hides menu when launching game
  if (
    R.includes(gameInfoQuery.referrer, ['live-casino'] && authenticated) ||
    R.includes(gameInfoQuery.referrer, ['casino']) ||
    R.includes('/sports', location.pathname)
  ) {
    return null
  }

  // Disables casino navigation when in the casino lobby,
  // but not when in game info view.
  const disabledCasinoNavigation =
    R.includes('/casino', location.pathname) &&
    R.not(R.includes('/games', location.pathname))

  return (
    <Wrapper>
      <QuickNavigation
        exact
        align="left"
        icon={icons.Home}
        location="/"
        onClick={props.onClick}
      />
      <QuickNavigation
        disabled={disabledCasinoNavigation}
        align="right"
        icon={icons.ViewModule}
        location="/casino"
        onClick={props.onClick}
      />
    </Wrapper>
  )
}

QuickNavigationMenu.propTypes = {
  onClick: PropTypes.func,
}

// For @loadable/components
export default QuickNavigationMenu
