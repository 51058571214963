import * as R from 'ramda'
import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'

export function ProgressBar(props) {
  return (
    <Common.Box display="flex" pb={1}>
      {R.times(
        active => (
          <Common.Box
            key={active}
            flexGrow="1"
            backgroundColor={
              active > props.step
                ? 'registration-progress-background'
                : 'registration-progress-active-background'
            }
            height="6px"
            borderRadius={1}
            mx="2px"
            transition="background-color 0.2s ease-in"
            data-testid="progress-bar.step"
          />
        ),
        props.steps
      )}
    </Common.Box>
  )
}

ProgressBar.propTypes = {
  steps: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
}

// for @loadable/components
export default ProgressBar
