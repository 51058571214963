import * as React from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import * as Common from '@rushplay/common'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { useI18n } from '@rushplay/i18n'

import { Amount } from './amount'
import { withNumberEasing } from './with-number-easing'

const NumberEasedAmount = withNumberEasing(Amount, {
  propName: 'children',
  easing: 'outQuint',
  speed: 5000,
  delay: 500,
})

function makeItRain() {
  const canvas = document.querySelector('#boom-moment')
  const ctx = canvas.getContext('2d')

  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
  canvas.id = 'boom-moment'

  const coin = new Image()
  coin.src = '/images/coin-sprite.png'
  // 440 wide, 40 high, 10 states

  coin.onload = () => {
    drawloop()
  }
  const coins = []

  function drawloop() {
    requestAnimationFrame(drawloop)

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    if (Math.random() < 0.3) {
      coins.push({
        x: (Math.random() * canvas.width) | 0,
        y: -50,
        dy: 3,
        s: 0.5 + Math.random(),
        state: (Math.random() * 10) | 0,
      })
    }
    let i = coins.length

    while (i--) {
      const x = coins[i].x
      const y = coins[i].y
      const s = coins[i].s
      const state = coins[i].state
      coins[i].state = state > 9 ? 0 : state + 0.3
      coins[i].dy += 0.3
      coins[i].y += coins[i].dy

      ctx.drawImage(
        coin,
        44 * Math.floor(state),
        0,
        44,
        40,
        x,
        y,
        44 * s,
        40 * s
      )

      if (y > canvas.height) {
        coins.splice(i, 1)
      }
    }
  }
}

const BalanceAmountAnimation = styled.div`
  animation: ${keyframes`
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  `};
  animation-duration: 4500ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
`

const CashbackAmountAnimation = styled.div`
  animation: ${keyframes`
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0.7);
      opacity: 0.6;
    }
  `};
  animation-duration: 4500ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
`

const DismissTextAnimation = styled.div`
  animation: ${keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 0.8;
    }
  `};
  animation-duration: 300ms;
  animation-fill-mode: forwards;
`

const duration = 300
const defaultStyles = {
  transform: 'scale(0)',
  transition: `transform ${duration}ms ease`,
}
const transitionStyles = {
  entering: { transform: 'scale(1)' },
  entered: { transform: 'scale(1)' },
  exiting: { transform: 'scale(0)' },
  exited: { transform: 'scale(0)' },
}

export function BoomCashMoment(props) {
  const i18n = useI18n()
  const animationNodeRef = React.useRef(null)
  const [amount, setAmount] = React.useState(
    props.balance - props.claimedCashback
  )
  const [cashback, setCashback] = React.useState(props.claimedCashback)

  React.useEffect(() => {
    makeItRain()
    setAmount(props.balance)
    setCashback(0)
  }, [])

  return (
    <React.Fragment>
      <canvas id="boom-moment" />
      <Common.Box
        position="fixed"
        width="100%"
        height="100%"
        left="0px"
        top="0px"
        fontSize={['60px', '80px']}
        color="g-text"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontWeight="bold"
        style={{ fontVariantNumeric: 'tabular-nums' }}
        fontFamily="Helvetica"
      >
        <Transition
          nodeRef={animationNodeRef}
          in={props.claimed}
          timeout={duration}
        >
          {state => (
            <Common.Box
              display="grid"
              textAlign="center"
              gridRowGap={4}
              ref={animationNodeRef}
              style={{
                ...defaultStyles,
                ...transitionStyles[state],
              }}
            >
              <Common.Box display="grid" gridRowGap={1}>
                <Common.Text fontSize={2}>
                  {i18n.translate('boom-cash-moment.balance')}
                </Common.Text>
                <BalanceAmountAnimation>
                  <NumberEasedAmount decimals="show">
                    {amount}
                  </NumberEasedAmount>
                </BalanceAmountAnimation>
              </Common.Box>
              <Common.Box display="grid" gridRowGap={1}>
                <Common.Text fontSize={2}>
                  {i18n.translate('boom-cash-moment.cashback')}
                </Common.Text>
                <CashbackAmountAnimation>
                  <NumberEasedAmount decimals="show">
                    {cashback}
                  </NumberEasedAmount>
                </CashbackAmountAnimation>
              </Common.Box>
              <Common.Box fontSize={1} height="14px">
                {props.dismissable && (
                  <DismissTextAnimation>
                    {i18n.translate('boom-cash-moment.dismiss')}
                  </DismissTextAnimation>
                )}
              </Common.Box>
            </Common.Box>
          )}
        </Transition>
      </Common.Box>
    </React.Fragment>
  )
}

BoomCashMoment.propTypes = {
  balance: PropTypes.number,
  claimedCashback: PropTypes.number,
  dismissable: PropTypes.bool,
  claimed: PropTypes.bool,
}
