import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { Toast } from '../toast'

export function WithdrawalLimitInfo(props) {
  const i18n = useI18n()

  React.useEffect(() => {
    props.onFetch()
  }, [])

  if (
    (!props.bonusBalanceCents || props.bonusBalanceCents === 0) &&
    !props.limitCents
  ) {
    return null
  }

  return (
    <Common.Box pb={1}>
      {props.bonusBalanceCents > 0 && (
        <Toast title={i18n.translate('toast.warning')} variant="warning">
          {i18n.translate('wallet.withdrawl.bonus-disclaimer', {
            bonusAmount: props.bonusBalanceCents,
            moneyAmount: props.moneyBalanceCents,
            wagerBalance: props.wagerBalanceCents,
          })}
        </Toast>
      )}
      {props.limitCents && (
        <Common.Box pt={1}>
          <Toast title={i18n.translate('toast.warning')} variant="warning">
            {i18n.translate('wallet.withdrawal.daily-withdraw-limit', {
              limitCents: props.limitCents,
              leftToWithdrawCents: props.leftToWithdrawCents,
            })}
          </Toast>
        </Common.Box>
      )}
    </Common.Box>
  )
}

WithdrawalLimitInfo.propTypes = {
  bonusBalanceCents: PropTypes.number,
  leftToWithdrawCents: PropTypes.number,
  limitCents: PropTypes.number,
  moneyBalanceCents: PropTypes.number,
  wagerBalanceCents: PropTypes.number,
  onFetch: PropTypes.func.isRequired,
}
