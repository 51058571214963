import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

import { Toast } from '../toast'

export function DepositLimitInformation(props) {
  const i18n = useI18n()

  React.useEffect(() => {
    props.onFetch()
  }, [])

  if (!props.limit) {
    return null
  }

  return (
    <React.Fragment>
      <Toast title={i18n.translate('toast.info')} variant="info">
        {i18n.translate(`wallet.deposit.max-limit-${props.limit.interval}`, {
          leftToDeposit: props.limit.leftToDepositCents,
          depositLimit: props.limit.limitCents,
        })}
      </Toast>
      <common.Box
        pt={2}
        fontSize={2}
        textAlign="center"
        style={{ textDecoration: 'underline' }}
      >
        <ReactRouter.Link to="/account/deposit-limits">
          {i18n.translate('wallet.deposit.change-limits')}
        </ReactRouter.Link>
      </common.Box>
    </React.Fragment>
  )
}

DepositLimitInformation.propTypes = {
  limit: PropTypes.object,
  onFetch: PropTypes.func.isRequired,
}
