import * as R from 'ramda'
import * as recompose from 'recompose'
import { connect } from 'react-redux'

import * as session from '../session'

import { QuickNavigationMenu as Component } from './quick-navigation-menu'

export const QuickNavigationMenu = R.compose(
  connect(state => ({ authenticated: session.isAuthenticated(state.session) })),
  recompose.branch(
    props => R.not(props.authenticated),
    recompose.renderNothing
  ),
  recompose.mapProps(R.omit(['authenticated']))
)(Component)
