import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Url from 'url'

import * as Configuration from './configuration'
import * as ServerConfiguration from './server-configuration'
import * as Session from './session'
import * as Player from './player'

function getPayerConfig(state) {
  return {
    host: Configuration.getPayerUrl(state.configuration),
    token: Session.getToken(state.session),
    userId: Player.getUsername(state.player),
    countryCode: Player.getCountryCode(state.player),
  }
}

export function useTransactionAmountLimits(transactionType) {
  const [limits, setLimits] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const payerConfig = ReactRedux.useSelector(getPayerConfig)
  const serverConfig = ServerConfiguration.useContext()

  const payerUrl = Url.format({
    pathname: '/api/transaction-limits',
    query: {
      transaction_type: transactionType,
      country_code: payerConfig.countryCode || serverConfig.country.alpha2,
      token: payerConfig.token,
      user_id: payerConfig.userId,
    },
  })

  React.useEffect(() => {
    const controller = new AbortController()

    fetch(`${payerConfig.host}${payerUrl}`, {
      method: 'GET',
      signal: controller.signal,
    })
      .then(res => res.json())
      .then(body => {
        setLimits({
          max: R.pathOr(Number.MAX_SAFE_INTEGER, ['data', 'max'], body),
          min: R.pathOr(0, ['data', 'min'], body),
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

    // Cancels request if longer than 5s
    const id = setTimeout(() => controller.abort(), 5000)

    return () => {
      // cancel request if unmounted before we get a response
      controller.abort()
      clearTimeout(id)
    }
  }, [payerConfig.host, payerUrl])

  return { limits, loading }
}
