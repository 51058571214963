import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Promotions from '../promotions'
import * as Theming from '../theming'
import { Button } from '../button'
import { HtmlContent } from '../html-content'

const endTextColor = {
  day: 'g-text',
  hour: 'textDanger',
  minutes: 'red',
}

export function PromotionCard(props) {
  const i18n = I18n.useI18n()

  const rewards = R.pathOr([], ['campaign', 'rewards'], props)

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        color="g-text"
        borderRadius={0}
        display="flex"
        alignItems="center"
        p={1}
        borderLeft="10px solid"
        borderColor={props.seen ? 'disabled' : 'unseen-promotion-border'}
      >
        <Common.Box
          flexShrink="0"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Common.Box
            borderRadius="9999px"
            backgroundImage={`url(${i18n.translate(
              `promotions.${props.id}.img-src`
            )})`}
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundColor="disabled"
            height="60px"
            width="60px"
            flexShrink="0"
            style={{ filter: props.upcoming ? 'grayscale(1)' : 'grayscale(0)' }}
          />
          {props.activeTo && !props.upcoming && (
            <Common.Box
              fontSize={0}
              pt={0}
              color={endTextColor[props.timeLeft.id]}
              textAlign="center"
              maxWidth="60px"
            >
              {i18n.translate(`promotions.time-left.${props.timeLeft.id}.end`, {
                timeLeft: props.timeLeft.end,
              })}
            </Common.Box>
          )}
          {props.upcoming && (
            <Common.Box
              fontSize={0}
              pt={0}
              color="g-text"
              textAlign="center"
              maxWidth="60px"
            >
              {i18n.translate(
                `promotions.time-left.${props.timeLeft.id}.start`,
                {
                  timeLeft: props.timeLeft.start,
                }
              )}
            </Common.Box>
          )}
        </Common.Box>
        <Common.Box
          pl={1}
          display="inline-flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Common.Box
            pb="4px"
            fontFamily="head"
            fontSize={2}
            fontWeight="bold"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {i18n.translate(
              `promotions.${props.id}.title`,
              R.mergeRight(
                Promotions.getPromotionMinMax(rewards),
                Promotions.getPromotionTranslationVariables(rewards)
              )
            )}
          </Common.Box>
          <Common.Text fontSize={1}>
            <HtmlContent
              html={{
                __html: i18n.translate(
                  `promotions.${props.id}.description`,
                  Promotions.getPromotionTranslationVariables(rewards)
                ),
              }}
            />
          </Common.Text>
          {i18n.hasTranslation(`promotions.${props.id}.terms-conditions`) && (
            <Common.Box
              fontSize={1}
              color="g-text"
              pt={0}
              style={{ textDecoration: 'underline' }}
            >
              <ReactRouter.Link to={`/promotions/${props.id}`}>
                {i18n.translate('promotions.terms-and-conditions')}
              </ReactRouter.Link>
            </Common.Box>
          )}
          {props.ctaLink && !props.upcoming && (
            <Common.Box flexGrow="1" pt={1}>
              <Button
                stretch
                disabled={props.campaign?.claimed}
                variant="primary"
                fontSize={1}
                onClick={props.onClaim}
              >
                {props.campaign?.claimed
                  ? i18n.translate('promotions.claimed')
                  : i18n.translate(`promotions.${props.id}.button-link`)}
              </Button>
            </Common.Box>
          )}
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

PromotionCard.defaultProps = {
  timeLeft: {},
}

PromotionCard.propTypes = {
  activeTo: PropTypes.string,
  activeFrom: PropTypes.string,
  campaign: PropTypes.object,
  ctaLink: PropTypes.string,
  timeLeft: PropTypes.object,
  id: PropTypes.string.isRequired,
  seen: PropTypes.bool,
  upcoming: PropTypes.bool,
  onClaim: PropTypes.func.isRequired,
}
