import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash.kebabcase'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Theming from '../theming'
import * as Icons from '../icons'
import { Button } from '../button'
import { Divider } from '../divider'
import { HtmlContent } from '../html-content'

function getTranslationKey(props) {
  switch (R.toLower(props.type)) {
    case 'freespins': {
      return `inventory.${kebabCase(props.type)}`
    }

    case 'featuretriggers': {
      return `inventory.${kebabCase(props.type)}${
        props.zeroWagering ? '.zero-wager' : ''
      }`
    }

    default: {
      return `inventory.${kebabCase(props.type)}`
    }
  }
}

const END_TIME_TEXT_COLOR = {
  day: 'g-text',
  hour: 'textDanger',
  minutes: 'red',
}

export function InventoryCard(props) {
  const i18n = I18n.useI18n()
  const disabled = !props.active || props.gameDisabledForClientType
  const imageUrl =
    props.gameImage ||
    i18n.translate(`inventory.${kebabCase(props.type)}.image`)

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        color="g-text"
        borderRadius={0}
        display="flex"
        flexDirection="column"
        p={1}
        borderLeft="10px solid"
        borderColor={props.seen ? 'disabled' : 'unseen-inventory-border'}
        overflow="hidden"
      >
        <Common.Box display="flex" alignItems="center">
          <Common.Box
            flexShrink="0"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Common.Box
              borderRadius={0}
              backgroundImage={`url(${imageUrl})`}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundColor="disabled"
              height="60px"
              width="60px"
              flexShrink="0"
              overflow="hidden"
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ filter: disabled ? 'grayscale(1)' : 'grayscale(0)' }}
            ></Common.Box>
            <Common.Box
              fontSize={0}
              pt={0}
              color={
                props.active ? END_TIME_TEXT_COLOR[props.timeLeft.id] : 'g-text'
              }
              textAlign="center"
              maxWidth="60px"
            >
              {props.active
                ? i18n.translate(
                    `inventory.time-left.${props.timeLeft.id}.expires-in`,
                    { timeLeft: props.timeLeft.expiresIn }
                  )
                : i18n.translate(
                    `inventory.time-left.${props.timeLeft.id}.available-in`,
                    { timeLeft: props.timeLeft.availableIn }
                  )}
            </Common.Box>
          </Common.Box>
          <Common.Box
            pl={1}
            display="inline-flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Common.Box
              pb="4px"
              fontFamily="head"
              fontSize={2}
              fontWeight="bold"
            >
              {i18n.translate(`${getTranslationKey(props)}.title`, {
                amount: props.amount,
              })}
            </Common.Box>
            <Common.Text fontSize={1}>
              <HtmlContent
                html={{
                  __html: i18n.translate(
                    `${getTranslationKey(props)}.description`,
                    {
                      amount: props.amount,
                      betAmountCents: props.betAmountCents,
                      gameTitle: props.gameTitle,
                      gameProvider: props.gameProvider,
                      spinMinValue: R.path(['minValue'], props.spinValueMinMax),
                      spinMaxValue: R.path(['maxValue'], props.spinValueMinMax),
                    }
                  ),
                }}
              />
            </Common.Text>

            {!disabled && (
              <Common.Box flexGrow="1" pt={1}>
                <Button
                  stretch
                  variant="primary"
                  fontSize={1}
                  onClick={props.onActivate}
                >
                  {i18n.translate('inventory.use')}
                </Button>
              </Common.Box>
            )}
          </Common.Box>
        </Common.Box>
        {props.gameDisabledForClientType && (
          <React.Fragment>
            <Common.Box py={1}>
              <Divider />
            </Common.Box>
            <Common.Box
              fontSize={1}
              color="info"
              fontStyle="italic"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Common.Box pr="4px" fontSize={2} display="inline-flex">
                <Icons.Info />
              </Common.Box>
              {i18n.translate('inventory.not-available.client-type')}
            </Common.Box>
          </React.Fragment>
        )}
      </Common.Box>
    </Theming.Alternative>
  )
}

InventoryCard.defaultProps = {
  timeLeft: {},
}

InventoryCard.propTypes = {
  amount: PropTypes.number,
  active: PropTypes.bool,
  betAmountCents: PropTypes.number,
  gameImage: PropTypes.string,
  gameTitle: PropTypes.string,
  gameProvider: PropTypes.string,
  gameDisabledForClientType: PropTypes.bool,
  timeLeft: PropTypes.object,
  id: PropTypes.number,
  seen: PropTypes.bool,
  spinValueMinMax: PropTypes.object,
  type: PropTypes.string,
  zeroWagering: PropTypes.bool,
  onActivate: PropTypes.func.isRequired,
}
