import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import * as Offers from '@rushplay/offers'

import * as Constants from './constants'
import * as Theming from './theming'
import * as CombinedSelectors from './combined-selectors'
import * as Icons from './icons'
import * as Utils from './utils'
import { Button } from './button'
import { CircularProgressbar } from './circular-progressbar'
import { Divider } from './divider'

function Picker(props) {
  const i18n = I18n.useI18n()

  // deselect offer if not eligable
  React.useEffect(() => {
    if (props.disabled) {
      props.onChange(false)
    }
  }, [props.disabled])

  const selected = props.selectedOfferId === props.id

  return (
    <Theming.Alternative>
      <Common.Box
        backgroundColor="g-bg"
        borderRadius={0}
        p={1}
        color="g-text"
        boxShadow={selected ? 'inset 0 0 1px 6px #009f66' : 'none'}
        style={{
          transition: 'box-shadow 150ms ease-in-out',
        }}
      >
        <Common.Flex>
          <Common.Box flexShrink="0">
            <CircularProgressbar
              disabled={props.disabled || (props.selectedOfferId && !selected)}
              id={props.id}
              backgroundUrl={i18n.translate(`offer-picker.${props.id}.img-src`)}
              percent={props.progress}
            />
          </Common.Box>
          <Common.Box
            pl={1}
            flexGrow="1"
            color={props.disabled ? 'text-disabled' : 'g-text'}
          >
            <Common.Box
              fontFamily="head"
              fontWeight="bold"
              fontSize={2}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {i18n.translate(
                `offer-picker.${props.id}.title`,
                props.translationVariables
              )}
            </Common.Box>
            <Common.Box pt={1} fontSize={0} fontStyle="italic">
              {i18n.translate(`offer-picker.rewards.title`)}
            </Common.Box>
            <Common.Box
              as="ul"
              display="grid"
              gridGap={0}
              ml={1}
              py={0}
              style={{ listStyle: 'disc' }}
            >
              {R.map(
                reward =>
                  reward.translationVariables && (
                    <Common.Box
                      as="li"
                      color={
                        !props.disabled && !reward.value && 'text-disabled'
                      }
                      fontSize={1}
                      key={reward.key}
                    >
                      {i18n.translate(reward.key, reward.translationVariables)}
                    </Common.Box>
                  ),
                props.rewards
              )}
            </Common.Box>
          </Common.Box>
        </Common.Flex>
        <Common.Space pt={0} pb={1}>
          <Divider />
        </Common.Space>
        <Common.Text fontSize={1} textDecoration="underline">
          <ReactRouter.Link to="/terms-and-conditions/welcome-offer">
            {i18n.translate('offer-picker.terms-and-conditions.link')}
          </ReactRouter.Link>
        </Common.Text>
        {!props.disabled && props.depositLeftForFullRewards > 0 && (
          <Common.Box
            fontSize={1}
            color="info"
            pt={1}
            fontStyle="italic"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box pr="4px" fontSize={2} display="inline-flex">
              <Icons.Info />
            </Common.Box>
            {i18n.translate('offer-picker.entice-greater-deposit', {
              depositLeftForFullRewardsCents: props.depositLeftForFullRewards,
            })}
          </Common.Box>
        )}
        {props.disabled && (
          <Common.Box
            fontSize={1}
            color="danger"
            pt={1}
            fontStyle="italic"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Common.Box pr="4px" fontSize={2} display="inline-flex">
              <Icons.Error />
            </Common.Box>
            {i18n.translate('offer-picker.minimum-deposit-amount', {
              minDepositAmount: props.translationVariables.minDepositAmount,
            })}
          </Common.Box>
        )}
        <Common.Box display="flex" justifyContent="center" mt="1">
          <Button
            disabled={props.disabled}
            type="button"
            variant={selected ? 'secondary-outlined' : 'secondary'}
            onClick={() => props.onChange(props.id)}
          >
            {i18n.translate(
              selected ? 'offer-picker.deselect' : 'offer-picker.claim'
            )}
          </Button>
        </Common.Box>
      </Common.Box>
    </Theming.Alternative>
  )
}

Picker.propTypes = {
  disabled: PropTypes.bool,
  depositLeftForFullRewards: PropTypes.number,
  id: PropTypes.number,
  progress: PropTypes.number,
  rewards: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
      translationVariables: PropTypes.object,
    })
  ),
  selectedOfferId: PropTypes.number,
  translationVariables: PropTypes.object,
  onChange: PropTypes.func,
}

export function OfferPicker(props) {
  const i18n = I18n.useI18n()
  const amountField = Forms.useField('#/properties/amount', {
    noRegister: true,
  })
  const amountCents = amountField.value ? amountField.value : 0
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT

  const offers = ReactRedux.useSelector(state =>
    CombinedSelectors.getCalculatedOffers(state, { amountCents })
  )

  function handleChange(offerId) {
    if (offerId && R.not(R.equals(offerId, props.selectedOfferId))) {
      props.onChangeOfferId(offerId)
    } else {
      props.onChangeOfferId(null)
    }
  }

  if (R.isEmpty(offers) || R.not(isDeposit)) {
    return null
  }

  return (
    <Common.Space pt={2}>
      <Common.Text
        fontSize={4}
        textAlign="center"
        fontWeight="bold"
        fontFamily="head"
      >
        {i18n.translate('offer-picker.select-offer')}
      </Common.Text>
      <Common.Box pt={2} display="grid" gridGap={0}>
        {R.map(
          offer => (
            <Picker
              disabled={amountCents < R.head(offer.rangeLimits)}
              key={offer.id}
              selectedOfferId={props.selectedOfferId}
              progress={offer.offerProgressPercent}
              depositLeftForFullRewards={
                offer.minDepositForFullRewards - amountCents
              }
              rewards={Offers.getOfferRewards(Utils.toArray(offer))}
              translationVariables={R.mergeAll([
                { minDepositAmount: R.head(offer.rangeLimits) },
                Offers.getMaxRewardsTranslationVariables(offers),
              ])}
              id={offer.id}
              onChange={handleChange}
            />
          ),
          offers
        )}
      </Common.Box>
    </Common.Space>
  )
}

OfferPicker.propTypes = {
  selectedOfferId: PropTypes.number,
  transactionType: PropTypes.string.isRequired,
  onChangeOfferId: PropTypes.func,
}

// for @loadable/components
export default OfferPicker
