import * as R from 'ramda'
import * as React from 'react'

// TODO: Move to @rushplay/common

// Easing functions copied from:
// https://github.com/streamich/ts-easing/blob/master/src/index.ts
const easing = {
  // No easing, no acceleration
  linear: n => n,
  // Acceleration until halfway, then deceleration
  inOutQuint: t =>
    t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t,
  // Accelerating from zero velocity
  inQuint: t => t * t * t * t * t,
  // Decelerating to zero velocity
  outQuint: t => 1 + --t * t * t * t * t,
}

/**
 * HoC that applies a timebased easing on specified prop
 * @param {ReactComponent} Component
 * @param {object} options
 * @param {string} [options.easing=linear] - Easing curve of animation
 * @param {number} [options.speed=500] - Length of animation in milliseconds
 * @param {number} [options.delay=0] - Delay of animaiton in milliseconds
 * @param {string} options.propName - Prop to ease
 */
export function withNumberEasing(Component, options = {}) {
  if (!options.propName) {
    throw new Error('options.propName is required')
  }

  return class NumberEasing extends React.Component {
    constructor(props) {
      super(props)
      this.timeout = null
      this.startAnimationTime = null
      this.updateNumber = this.updateNumber.bind(this)
      this.handleAnimation = this.handleAnimation.bind(this)
      this.state = {
        displayValue: R.defaultTo(0, props[options.propName]),
        previousValue: R.defaultTo(0, props[options.propName]),
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps[options.propName] === this.props[options.propName]) {
        return
      }

      this.setState({
        previousValue: this.state.displayValue,
      })

      if (options.delay) {
        this.delayTimeout = setTimeout(() => {
          this.startAnimationTime = Date.now()
          this.handleAnimation()
        }, options.delay)
      } else {
        this.startAnimationTime = Date.now()
        this.handleAnimation()
      }
    }

    handleAnimation() {
      window.cancelAnimationFrame(this.requestID)
      this.requestID = window.requestAnimationFrame(this.updateNumber)
    }

    updateNumber() {
      const speed = R.defaultTo(500, options.speed)
      const easingType = R.defaultTo('linear', options.easing)

      const now = Date.now()
      const elapsedTime = Math.min(speed, now - this.startAnimationTime)
      const progress = easing[easingType](elapsedTime / speed)
      const delta = this.props[options.propName] - this.state.previousValue
      const currentDisplayValue = Math.round(
        delta * progress + this.state.previousValue
      )

      this.setState({
        displayValue: currentDisplayValue,
      })

      if (elapsedTime < speed) {
        this.timeout = setTimeout(this.handleAnimation, 16)
      } else {
        this.setState({
          previousValue: this.props[options.propName],
        })
      }
    }

    componentWillUnmount() {
      window.cancelAnimationFrame(this.requestID)
      clearTimeout(this.timeout)
      clearTimeout(this.delayTimeout)
    }

    render() {
      return React.createElement(
        Component,
        R.assoc(
          options.propName,
          Math.round(this.state.displayValue),
          this.props
        )
      )
    }
  }
}
