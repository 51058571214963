import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'

import * as Constants from './constants'
import * as Player from './player'
import * as Theming from './theming'
import { DepositCampaignOfferPicker } from './deposit-campaign-offer-picker'
import { DepositLimitInformation } from './deposit-limit-info'
import { HtmlContent } from './html-content'
import { InputField } from './input-field'
import { PendingTransactions } from './pending-transactions'
import { SubmitButton } from './submit-button'
import { WithdrawalLimitInfo } from './withdrawal-limit-info'
import { getSearchQuery } from './get-search-query'

function normalizeAmount(value) {
  const amount = Number.parseInt(value, 10)
  if (Number.isNaN(amount)) {
    return 0
  }
  return Math.round(amount * 100)
}

function parseAmount(value) {
  const amount = Math.round(value / 100)
  if (Number.isNaN(amount)) {
    return ''
  }

  return amount
}

export function TransactionAmount(props) {
  const i18n = I18n.useI18n()
  const location = ReactRouter.useLocation()
  const query = getSearchQuery(location)
  const currency = Player.useCurrency()
  const scope = '#/properties/amount'
  const isDeposit = props.transactionType === Constants.TransactionType.DEPOSIT
  const cddStatus = ReactRedux.useSelector(state =>
    Player.getCddStatus(state.player)
  )
  const showCddInfo =
    props.transactionType === Constants.TransactionType.WITHDRAWAL &&
    (cddStatus === 'initiated' || cddStatus === 'failed')

  const lastDepositAmount = ReactRedux.useSelector(state =>
    Player.getLastDepositAmount(state.player)
  )
  const firstDeposit = ReactRedux.useSelector(state =>
    Player.getInitialDeposit(state.player)
  )
  const initialValue = isDeposit
    ? R.defaultTo(firstDeposit, lastDepositAmount)
    : null

  const settingsAvailability = ReactRedux.useSelector(state =>
    Jurisdiction.getSettingsAvailability(state.jurisdiction)
  )

  return (
    <>
      <PendingTransactions key="pending-transactions" />
      <Theming.Primary>
        <InputField
          disabled={props.disabled}
          currency={currency}
          inputMode="decimal"
          parse={parseAmount}
          normalize={normalizeAmount}
          initialValue={query.amount || initialValue}
          scope={scope}
          data-testid={`wallet.${props.transactionType}.amount`}
        />
      </Theming.Primary>
      {props.offerPicker}
      {isDeposit && <DepositCampaignOfferPicker />}
      <Common.Box pt={2} pb={2} display="flex" justifyContent="center">
        <SubmitButton
          disabled={cddStatus === 'initiated' || cddStatus === 'failed'}
          onlyScopes={[scope]}
          data-testid={`wallet.${props.transactionType}.submit`}
        >
          {i18n.translate(`transaction.${props.transactionType}.amount.submit`)}
        </SubmitButton>
      </Common.Box>
      {settingsAvailability.depositLimits && (
        <Common.Box
          pb={2}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ReactRouter.Link to="/account/deposit-limits">
            <Common.Text fontSize={1}>
              {i18n.translate(`transaction.set-limit`)}
            </Common.Text>
          </ReactRouter.Link>
        </Common.Box>
      )}
      {isDeposit ? <DepositLimitInformation /> : <WithdrawalLimitInfo />}
      {showCddInfo && (
        <>
          <HtmlContent
            html={{
              __html: i18n.translate(`wallet.cdd-info.${cddStatus}`),
            }}
          />
          <Common.Box pt={1} textAlign="center" fontSize={1}>
            <ReactRouter.Link to="/account/my-identity">
              {i18n.translate(`wallet.cdd-info.link`)}
            </ReactRouter.Link>
          </Common.Box>
        </>
      )}
    </>
  )
}

TransactionAmount.propTypes = {
  offerPicker: PropTypes.node,
  transactionType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

// for @loadable/components
export default TransactionAmount
