import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'

import * as Icons from '../icons'
import * as Analytics from '../analytics'
import { Amount } from '../amount'
import { BoomCashMoment } from '../boom-cash-moment'
import { Button } from '../button'
import { LoyaltyProgramRoundProgressBar } from '../loyalty-program-round-progress-bar'
import { QueryDrawer } from '../query-drawer'

const duration = 350
const BoomMomentWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  background-image: radial-gradient(#000, transparent);
  z-index: 10;
  opacity: 0;
  transition: opacity ${duration}ms ease;
`
const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

export function LoyaltyDrawer(props) {
  const [claimed, setClaimed] = React.useState(false)
  const [clickBlocker, setClickBlocker] = React.useState(false)
  const [newPlayerBalance, setNewPlayerBalance] = React.useState(0)
  const animationNodeRef = React.useRef(null)
  const i18n = I18n.useI18n()
  const history = ReactRouter.useHistory()
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    if (claimed) {
      setClickBlocker(true)

      const id = setTimeout(() => {
        setClickBlocker(false)
      }, 5000)

      return () => {
        clearTimeout(id)
      }
    }
  }, [claimed])

  function handleExit() {
    if (!clickBlocker) {
      setClickBlocker(false)
      setClaimed(false)
    }
  }

  return (
    <React.Fragment>
      <QueryDrawer
        activeQueryName="loyalty-program"
        title={i18n.translate('loyalty-program.title')}
        onSecondaryAction={() => history.push('/faq/loyalty-program')}
        secondaryActionIcon={Icons.Info}
      >
        <React.Fragment>
          <Common.Box
            display="grid"
            justifyContent="center"
            color="g-text"
            fontSize={1}
            textAlign="center"
            backgroundColor="g-bg"
            borderRadius="9999px"
          >
            <Common.Space my={1}>
              <LoyaltyProgramRoundProgressBar height="98px" />
              <Common.Box
                fontSize={2}
                fontWeight="bold"
                fontFamily="head"
                data-testid="loyalty-program.cycle-counter"
              >
                {i18n.translate('loyalty-program.cycle-counter', {
                  currentSpin: props.currentSpin,
                  cycleLength: props.cycleLength,
                })}
              </Common.Box>
            </Common.Space>
          </Common.Box>
          <Common.Box display="flex" alignItems="center" mt={1}>
            <Common.Box flexGrow="1" fontSize={2}>
              {i18n.translate('loyalty-program.cycle-collected')}
            </Common.Box>
            <Common.Box
              display="flex"
              flex="0 1 150px"
              backgroundColor="g-bg"
              py={0}
              justifyContent="center"
              fontSize={3}
              fontWeight="bold"
              borderRadius={1}
              fontFamily="head"
            >
              <Amount
                decimals="show"
                data-testid="loyalty-program.current-cycle-bonus.mainBetBalanceCents"
              >
                {props.mainBetBalanceCents}
              </Amount>
            </Common.Box>
          </Common.Box>
          <Common.Space py={0} />
          <Common.Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Common.Box flexGrow="1" fontSize={2}>
              {i18n.translate('loyalty-program.next-cycle-bonus')}
            </Common.Box>
            <Common.Box
              display="flex"
              flex="0 1 150px"
              justifyContent="center"
              backgroundColor="g-bg"
              py={0}
              fontSize={3}
              fontWeight="bold"
              borderRadius={1}
              color="rgba(255, 255, 255, 0.5)"
              fontFamily="head"
            >
              <Amount
                decimals="show"
                data-testid="loyalty-program.next-cycle-value"
              >
                {props.nextBetBalanceCents}
              </Amount>
            </Common.Box>
          </Common.Box>
          {!props.disabled && (
            <Common.Box
              display="grid"
              gridGap={2}
              fontSize={4}
              fontFamily="head"
              textAlign="center"
              mt={2}
              py={2}
              px={1}
              borderRadius={1}
              backgroundImage="url(/images/ripple-background-blue.svg), linear-gradient(257deg, #482d95 55%, #613dc9 11%)"
              backgroundSize="cover"
              backgroundPosition="center"
            >
              {i18n.translate('loyalty-program.claimable')}
              <Common.Box fontSize={7} fontWeight="bold" fontFamily="head">
                <Amount
                  decimals="show"
                  data-testid="loyalty-program.claimable-cents"
                >
                  {props.claimableBalanceCents}
                </Amount>
              </Common.Box>
              <Button
                variant="primary"
                disabled={props.disabled || props.loading}
                onClick={() =>
                  props.onClaim(newBalance => {
                    dispatch(
                      Analytics.cashOut({
                        title: 'LoyaltyDrawer',
                        path: location.pathname,
                      })
                    )
                    setNewPlayerBalance(newBalance)
                    setClaimed(true)
                  })
                }
                data-testid="loyalty-program.claim"
              >
                {i18n.translate('loyalty-program.claim')}
              </Button>
            </Common.Box>
          )}
          <Common.Box
            pt={3}
            opacity="0.5"
            color="g-text"
            fontSize={1}
            textAlign="center"
          >
            <ReactRouter.Link
              to="/terms-and-conditions/loyalty-program"
              data-testid="loyalty-program.terms-and-conditions.link"
            >
              {i18n.translate('loyalty-program.terms-and-conditions')}
            </ReactRouter.Link>
          </Common.Box>
        </React.Fragment>
      </QueryDrawer>
      <Transition
        nodeRef={animationNodeRef}
        in={claimed}
        timeout={duration}
        mountOnEnter
        unmountOnExit
      >
        {state => (
          <BoomMomentWrapper
            ref={animationNodeRef}
            style={transitionStyles[state]}
            onClick={handleExit}
          >
            <BoomCashMoment
              claimedCashback={props.claimableBalanceCents}
              balance={newPlayerBalance}
              dismissable={!clickBlocker}
              claimed={claimed}
            />
          </BoomMomentWrapper>
        )}
      </Transition>
    </React.Fragment>
  )
}

LoyaltyDrawer.defaultProps = {
  currentSpin: 0,
  cycleLength: 0,
  progress: 0,
  claimableBalanceCents: 0,
  mainBetBalanceCents: 0,
  nextBetBalanceCents: 0,
}

LoyaltyDrawer.propTypes = {
  currentSpin: PropTypes.number,
  cycleLength: PropTypes.number,
  claimableBalanceCents: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  mainBetBalanceCents: PropTypes.number,
  nextBetBalanceCents: PropTypes.number,
  progress: PropTypes.number,
  onClaim: PropTypes.func.isRequired,
}

// for @loadable/components
export default LoyaltyDrawer
