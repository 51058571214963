import * as React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import css from '@styled-system/css'
import styled from '@emotion/styled'
import { noneOf } from '@rushplay/common'

import { RoundButton } from './round-button'

const IconWrapper = styled.div`
  ${css({
    marginLeft: 1,
    marginTop: 5,
  })}
`

const Dot = styled.div`
  border-radius: 9999px;
  width: 0.5em;
  height: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  display: none;
  ${css({
    bg: 'nav-highlight',
  })}
`

const Background = styled(NavLink, {
  shouldForwardProp: noneOf(['align']),
})`
  width: 80px;
  height: 127px;
  pointer-events: visiblepainted;
  clip-path: ${props =>
    props.align === 'left' ? 'url(#left)' : 'url(#right)'};
  ${css({
    backgroundColor: 'nav',
    display: ['flex', 'none'],
  })}

  &.active {
    .dot {
      display: block;
    }
  }
`

// TODO: Replace using '#' with something else, to make link do nothing.
export function QuickNavigation(props) {
  return (
    <Background
      align={props.align}
      exact={props.exact}
      to={props.disabled ? '#' : props.location}
      onClick={props.onClick}
    >
      <IconWrapper>
        <RoundButton color="nav-highlight" outlined fontSize={6}>
          <props.icon />
        </RoundButton>
        <Dot className="dot" />
      </IconWrapper>
    </Background>
  )
}

QuickNavigation.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  icon: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
