import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouterDom from 'react-router-dom'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import * as Api from '@rushplay/api-client'
import * as Emotion from '@emotion/core'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Analytics from './analytics'
import * as Promotions from './promotions'
import * as Inventory from './inventory'
import * as Session from './session'
import * as ServerConfiguration from './server-configuration'
import { PromotionCampaigns } from './promotion-campaigns'
import { PromotionInventory } from './promotion-inventory'
import { QueryDrawer } from './query-drawer'
import { getSearchQuery } from './get-search-query'
import { usePrev } from './use-prev'
import { useSafeUpdateQuery } from './use-safe-update-query'

const TabMenuItem = styled(ReactRouterDom.Link, {
  shouldForwardProp: Common.noneOf(['active']),
})`
  ${props =>
    css({
      p: 1,
      backgroundColor: props.active ? 'nav-hover' : 'nav',
      '&:hover': {
        backgroundColor: 'nav-hover',
      },
      fontFamily: 'head',
    })};

  text-align: center;
  flex: 1;
  ${props => Emotion.css`
    box-shadow: ${
      props.active
        ? `inset 0 -3px 0 0 ${props.theme.colors['nav-highlight']}`
        : 'inset 0 -1px 0 0 rgba(255, 255, 255, 0.2)'
    };
  `};
  cursor: pointer;
  user-select: none;
`

export function PromotionsDrawer() {
  const i18n = I18n.useI18n()
  const location = ReactRouterDom.useLocation()
  const dispatch = ReactRedux.useDispatch()
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const query = getSearchQuery(location)
  const prevQuery = usePrev(query)
  const campaignsQuery = useSafeUpdateQuery({ promotions: 'campaigns' })
  const inventoryQuery = useSafeUpdateQuery({ promotions: 'inventory' })

  const { country } = ServerConfiguration.useContext()

  React.useEffect(() => {
    if (prevQuery.promotions !== query.promotions) {
      if (query.promotions) {
        // Fetch promotions and inventory on open
        if (authenticated) {
          dispatch([Promotions.fetch(), Inventory.fetch()])
        } else {
          dispatch([Promotions.fetch(country.alpha2)])
        }
        if (R.equals(query.promotions, 'campaigns')) {
          dispatch(
            Analytics.openPromotionMenu({
              path: location.pathname,
              title: document.title,
            })
          )
        }
      } else {
        // Mark as read when drawer closes
        if (authenticated) {
          dispatch(
            Api.markNotificationsAsSeen({
              success: () => [
                Promotions.markAllAsSeen(),
                Inventory.markAllAsSeen(),
              ],
              version: 1,
            })
          )
        } else {
          dispatch(Promotions.markAllAsSeen())
        }
      }
    }
  }, [prevQuery, query])

  return (
    <QueryDrawer
      activeQueryName="promotions"
      title={i18n.translate('promotion-notifications.title')}
    >
      <Common.Box pb={1} flexGrow="1" display="flex" flexDirection="column">
        {authenticated && (
          <Common.Box
            fontSize={2}
            color="g-text"
            display="flex"
            mx="-16px"
            mb={1}
            flexShrink="0"
          >
            <TabMenuItem
              to={`?${campaignsQuery}`}
              active={R.equals(query.promotions, 'campaigns')}
            >
              {i18n.translate('promotions.tab.campaigns')}
            </TabMenuItem>
            <TabMenuItem
              to={`?${inventoryQuery}`}
              active={R.equals(query.promotions, 'inventory')}
            >
              {i18n.translate('promotions.tab.inventory')}
            </TabMenuItem>
          </Common.Box>
        )}

        {R.equals(query.promotions, 'campaigns') && <PromotionCampaigns />}

        {R.equals(query.promotions, 'inventory') && <PromotionInventory />}
      </Common.Box>
    </QueryDrawer>
  )
}

// for @loadable/components
export default PromotionsDrawer
