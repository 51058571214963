import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

const Track = styled.circle`
  ${css({
    stroke: 'disabled',
  })};
`

const Bar = styled.circle`
  ${css({
    stroke: 'secondary',
  })};
  transform: rotate(-90deg);
  transform-origin: center;

  transition: stroke-dashoffset 200ms ease-in-out;
`

const RADIUS = 32
const CIRCUMFERENCE = 2 * Math.PI * RADIUS

export function CircularProgressbar(props) {
  const dashoffset = CIRCUMFERENCE * (1 - props.percent / 100)

  return (
    <Common.Box
      as="svg"
      width="70px"
      height="70px"
      viewBox="0 0 70 70"
      filter={props.disabled ? 'grayscale(1)' : 'grayscale(0)'}
      style={{ transition: 'filter 200ms ease-in-out' }}
    >
      <defs>
        <pattern
          id={props.id}
          height="1"
          width="1"
          x="0"
          y="0"
          viewBox="0 0 1 1"
          patternUnits="objectBoundingBox"
        >
          <image height="1" width="1" href={props.backgroundUrl} />
        </pattern>
      </defs>
      <circle cx="50%" cy="50%" r={RADIUS} fill="#291332" />
      <Track
        cx="50%"
        cy="50%"
        r={RADIUS}
        strokeWidth="5"
        fill={`url(#${props.id})`}
      />
      <Bar
        cx="50%"
        cy="50%"
        fill="none"
        r={RADIUS}
        strokeWidth="5.5"
        strokeDasharray={CIRCUMFERENCE}
        strokeDashoffset={dashoffset}
      />
    </Common.Box>
  )
}

CircularProgressbar.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percent: PropTypes.number,
  backgroundUrl: PropTypes.string,
}
